<template>
  <main class="faq">
    <div class="container">
      <heading-1>F.A.Q.</heading-1>
      <p class="mb-24">
        Her kan du finde svar på de mest almene spørgsmål. Har du et spørgsmål
        som ikke er besvaret nedenfor, er du altid velkommen til at
        <router-link to="kontakt" class="link">kontakte os</router-link>, eller
        <link-booking class="link">booke tid</link-booking>
        til en konsultation.
      </p>
      <heading-2>Spørgsmål og svar</heading-2>
      <ul>
        <li
          v-for="question in $store.getters.getData.faq"
          :key="question.id"
          class="relative border-t"
        >
          <h3
            class="font-medium text-lg cursor-pointer flex items-center select-none py-6"
            @click="
              current == question.id ? (current = 0) : (current = question.id)
            "
          >
            <plus
              class="transform transition-transform"
              :class="current == question.id ? ' rotate-45' : ''"
            />
            <span class="ml-4" v-html="question.title" />
          </h3>

          <div
            class="-mt-2 mb-6 ml-8"
            v-html="question.content"
            v-show="current == question.id"
          />
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import Plus from "../assets/icons/Plus.vue";
import Heading1 from "../components/Heading1.vue";
import Heading2 from "../components/Heading2.vue";
import LinkBooking from "../components/LinkBooking.vue";

export default {
  name: "FAQ",

  components: {
    Plus,
    Heading1,
    Heading2,
    LinkBooking,
  },

  data() {
    return {
      current: 0,
    };
  },
};
</script>
